import { IndexPageConfig } from "../../../gatsby/config/create-pages/types"
import { PathPrefix } from "../../core/constants"

export const guideIndexConfig: IndexPageConfig = {
  pagePath: PathPrefix.Guides,
  searchConfig: {
    filters: `fields.articleType:Guide`
  },
  attributes: ["type", "time", "title", "url"],
  indexes: ["newest", "oldest", "alphabetical"],
  filters: [
    {
      attribute: "fields.primaryTopic.fields.name",
      title: "search-filter-topics"
    }
  ],
  defaultSearchIndex: "newest"
}
