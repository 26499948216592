import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { Page } from "@components/page/page"
import { guideIndexConfig } from "@features/guides/guide-index-config"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { IndexHeader } from "@components/index-header/index-header"
import { FeaturedResources } from "@components/featured-resources/featured-resources"
import { SearchResults } from "@features/search/components/search-results/search-results"
import { getFilteredIndexes } from "@features/search/search-helpers"

export const Guides: FunctionComponent<PageProps> = ({ ...props }) => {
  const { header, featuredResources, metadata } = useIndexPageData("guides")

  return (
    <Page
      {...props}
      metadata={metadata}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Guides,
        defaultSearchIndex: guideIndexConfig.defaultSearchIndex
      }}
    >
      <IndexHeader header={header} />
      <FeaturedResources
        shouldUpdateList
        pageKey="guides"
        labelId="featured-guides"
        list={featuredResources}
        type="guide"
      />
      <SearchResults
        filters={guideIndexConfig.filters}
        config={guideIndexConfig.searchConfig}
        variant="list"
        indexes={
          guideIndexConfig.indexes
            ? getFilteredIndexes(guideIndexConfig.indexes)
            : undefined
        }
      />
    </Page>
  )
}

export default Guides
